import React from 'react';
import styled from 'styled-components';
import { Typography } from 'amphitheatre';

import Layout from 'src/Layout';

import CallToAction from 'src/Components/CallToAction';
import Container from 'src/Components/ContentContainer';
import SEO from 'src/Components/SEO';

import * as Base from 'src/page-styles/base.styled';

import ImageAcademic from 'src/images/company/academic.svg';
import ImageArtist from 'src/images/company/artist.svg';
import ImageExperiment from 'src/images/company/experiment.svg';
import ImageIntegrity from 'src/images/company/integrity.svg';
import ImageWork from 'src/images/company/work.svg';
import ImageYourself from 'src/images/company/yourself.svg';

const CoreValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-flow: column;
`;

const CoreValueImage = styled.img`
  width: 256px;
`;

const CoreValueTitle = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1.25rem;
  padding: 2rem 0 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;

  .core-value:not(:first-child) {
    margin-top: 2rem;
  }

  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    .core-value:not(:first-child) {
      margin-top: 0rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const { Header } = Typography;

function Company(): JSX.Element {
  return (
    <>
      <SEO
        title="About Saber | Saber Astronautics"
        description="Saber Astronautics is a global space engineering firm. We maintain specialised knowledge to offer the best in space mission design and operations products and services."
        url="https://www.saberastro.com/company"
      />
      <Layout>
        <Container.Dark>
          <Base.MenuGap />
          <Base.Heading>WHAT DO WE DO?</Base.Heading>
          <Base.Text style={{ textAlign: 'justify' }}>
            <Base.Paragraph>
              Saber Astronautics is a high impact space engineering company
              operating in Australia and in the USA. We provide “next
              generation” spaceflight operations services and software,
              including space mission design and space operations services for
              launch vehicles, spacecraft, and payloads from inception to
              deorbit.
            </Base.Paragraph>
            <Base.Paragraph>
              Founded in 2008, Saber Astronautics now operates multiple mission
              control centres, laboratories, and hubs:
              <ul>
                <li>
                  The Responsive Space Operations Centre (RSOC) and laboratory
                  in Boulder Colorado
                </li>
                <li>
                  The Responsive Space Operations Centre (RSOC) in Adelaide
                </li>
                <li>Space research and development laboratory in Sydney</li>
                <li>
                  The Wolfpack Space Hub (spaceflight incubator) in Sydney
                </li>
                <li>Saber's US DoD integration team in Colorado Springs</li>
              </ul>
            </Base.Paragraph>
            <Base.Paragraph>
              Our company’s mission is the democratization of space, reducing
              barriers to flight, and making space as easy as driving a car. We
              are a trusted supplier to government customers with national
              strategic missions to include NASA, and US Space Forces in the
              USA, and Australian Defence and the Australian Space Agency.
              Additionally, we sell to a growing list of commercial customers in
              both traditional and new markets.
            </Base.Paragraph>
            <br />
            <Header>
              <Base.Yellow>Core Values</Base.Yellow>
            </Header>
            <Base.Paragraph>
              At Saber Astronautics we have 6 core values which we ask our
              employees to wholeheartedly embrace.
            </Base.Paragraph>
          </Base.Text>
          <Wrapper>
            <CoreValue className="core-value">
              <CoreValueImage src={ImageYourself} />
              <CoreValueTitle>Be yourself</CoreValueTitle>
            </CoreValue>
            <CoreValue className="core-value">
              <CoreValueImage src={ImageExperiment} />
              <CoreValueTitle>Embrace experimentation</CoreValueTitle>
            </CoreValue>
            <CoreValue className="core-value">
              <CoreValueImage src={ImageWork} />
              <CoreValueTitle>Work to standard, not to time</CoreValueTitle>
            </CoreValue>
            <CoreValue className="core-value">
              <CoreValueImage src={ImageAcademic} />
              <CoreValueTitle>Embrace academic rigour</CoreValueTitle>
            </CoreValue>
            <CoreValue className="core-value">
              <CoreValueImage src={ImageArtist} />
              <CoreValueTitle>Embrace artistic excellence</CoreValueTitle>
            </CoreValue>
            <CoreValue className="core-value">
              <CoreValueImage src={ImageIntegrity} />
              <CoreValueTitle>Integrity above self</CoreValueTitle>
            </CoreValue>
          </Wrapper>
        </Container.Dark>
        <CallToAction
          header="We are always looking for talent"
          linkText="Apply now"
          to="/careers/"
        />
      </Layout>
    </>
  );
}

export default Company;
